<template>
  <div>
    <div class="filters-bar">
      <div>
        <div class="d-flex ml-auto align-items-center str-filters">
          <span>Select Store :</span>
          <base-input class="base-input">
            <select style="cursor:pointer;" v-model="selectedStore" class="form-control" id="exampleFormControlSelect1">
              <option value="all">All</option>
              <option :value="store._id" v-for="(store,index) in stores" :key="index">{{store.name}}</option>
            </select>
          </base-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card style="position: relative;" title="Orders">
          <i class="fa-solid fa-filter fa-lg" @click="isFilters = !isFilters"
          style="color: #e14eca; position: absolute; position: absolute;
          right: 2%;
          top: 7%;
          cursor: pointer;">
             </i>
          <div v-if="isFilters" class="form">
            <div class="row">
              <div class="col-8">
                <base-input label="Search" v-model="searchQuery" type="text" placeholder="name, email, phone number"/>
              </div>
              <div class="col-4 pt-3">
                <base-button @click="searchBy()" simple type="primary">Apply</base-button>
              </div>
            </div>
          </div>
          <div class="d-flex actions-bar">
            <base-button v-if="false" @click="newOrder()" class="ml-auto" type="primary" >New Order</base-button>
          </div>
          <div class="table-responsive">
            <base-table :data="table1.data"
                        :columns="table1.columns"
                        v-on:emitToEdit="editOrder($event)"
                        v-on:emitToDelete="deleteOrder($event)"
                        thead-classes="text-primary">
            </base-table>
            <Pagination
              :currentPage="pagination.currentPage"
              :totalPages="pagination.totalPages"
              :totalRecords="pagination.totalRecords"
              @updatePage="handlePageChange"
            />
          </div>
        </card>
      </div>

      <!--<div class="col-12">
        <card class="card-plain">
          <div class="table-full-width table-responsive">
            <base-table :title="table2.title" :sub-title="table2.subTitle" :data="table2.data"
                         :columns="table2.columns">

            </base-table>
          </div>
        </card>
      </div>-->
    </div>
  </div>
</template>
<script>
import { BaseTable } from "@/components";
import NotificationTemplate from '@/pages/Notifications/NotificationTemplate';
import Pagination from "@/components/Pagination.vue";
const tableColumns = ["firstname","lastname", "phone", "date","price","store", "actions"];
const tableData = [
  {
    id: 1,
    name: "Dakota Rice",
    email: "$36.738",
    phone: "Niger",
    points: "Oud-Turnhout",
  },
  {
    id: 2,
    name: "Minerva Hooper",
    email: "$23,789",
    phone: "Curaçao",
    points: "Sinaai-Waas"
  },
  {
    id: 3,
    name: "Sage Rodriguez",
    email: "$56,142",
    phone: "Netherlands",
    points: "Baileux"
  },
  {
    id: 4,
    name: "Philip Chaney",
    email: "$38,735",
    phone: "Korea, South",
    points: "Overland Park"
  },
  {
    id: 5,
    name: "Doris Greene",
    email: "$63,542",
    phone: "Malawi",
    points: "Feldkirchen in Kärnten"
  },
  {
    id: 6,
    name: 'Mason Porter',
    email: '$98,615',
    phone: 'Chile',
    points: 'Gloucester'
  },
  {
    id: 7,
    name: 'Jon Porter',
    email: '$78,615',
    phone: 'Portugal',
    points: 'Gloucester'
  }
];

export default {
  components: {
    BaseTable,
    NotificationTemplate,
    Pagination
  },
  data() {
    return {
      isFilters:false,
      searchQuery: '',
      page:1,
      limit:10,
      pagination: {
        currentPage: 1,
        totalPages: 1,
        totalRecords: 0
      },
      table1: {
        title: "Simple Table",
        columns: [...tableColumns],
        data: [] //[...tableData]
      },
      table2: {
        title: "Table on Plain Background",
        columns: [...tableColumns],
        data: [...tableData]
      },
      listOrders:[
        
      ],
      selectedStore:''
    };
  },
  async mounted() {
    await this.$store.dispatch('loadStores');
    let selectedStoreIndex = localStorage.getItem("selectedStoreIndex");
    console.log('selected store index_____',selectedStoreIndex);
    if(selectedStoreIndex == 'all') this.selectedStore = 'all';
    else this.selectedStore = this.stores[selectedStoreIndex]._id;
  },
  computed:{
    stores(){
      return this.$store.state.stores 
    },
    orders(){
      return this.$store.state.orders;
    },
    orders_info(){
      return this.$store.state.orders_info;
    }
  },
  watch:{
    async selectedStore(newVal,oldVal){
      for (let index = 0; index < this.stores.length; index++) {
        const id_store = this.stores[index]._id;
        if(id_store == newVal) localStorage.setItem("selectedStoreIndex", index);
        if(newVal == 'all') localStorage.setItem("selectedStoreIndex", 'all');
      }
      let storeId = newVal;
      await this.$store.dispatch('loadOrders', {
        page: this.page,
        limit: this.limit,
        storeId: storeId,
        searchQuery: this.searchQuery  // assuming searchQuery is the search input
      });
      this.formData();
    }
  },
  methods:{
    notifyVue(verticalAlign, horizontalAlign, msg, type) {
      const color = Math.floor(Math.random() * 4 + 1);
      this.$notify({
          component: NotificationTemplate,
          message: msg,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: horizontalAlign,
          verticalAlign: verticalAlign,
          type: type, //this.type[color],
          timeout: 3000
      });
    },
    async searchBy(){
      let storeId = this.selectedStore;
      await this.$store.dispatch('loadOrders', {
        page: this.page,
        limit: this.limit,
        storeId: storeId,
        searchQuery: this.searchQuery  // assuming searchQuery is the search input
      });
      this.getData();
    },
    editOrder(id){
      console.log('to edit order',id);
      this.$router.push(`/orders/update/${id}`);
    },
    async deleteOrder(id){
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to delete this Order',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, proceed!',
        cancelButtonText: 'Cancel',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          // User confirmed, perform the action
          let res = await this.$store.dispatch('removeOrder',id);
          if(res.status == 200){
            this.notifyVue('bottom', 'center', 'Order deleted successfuly', 'success');
            await this.$store.dispatch('loadOrders', {
              page: this.page,
              limit: this.limit,
              storeId: this.selectedStore,
              searchQuery: this.searchQuery  // assuming searchQuery is the search input
            });
            this.getData();
          }else{
            this.notifyVue('bottom', 'center', 'Error delete Order', 'danger');
          }
        }
      });
    },
    newOrder(){
      this.$router.push('/orders/create')
    },
    async getData(){
     // await this.$store.dispatch('loadOrders');
      this.formData();
    },
    async formData(){
      this.table1.data = [];
      this.listOrders = [];
      this.orders.forEach(order => {
        let ord = {
          _id: order._id,
          firstname: (order.customerId && order.customerId.firstname ) ?  order.customerId.firstname : '',
          lastname: (order.customerId && order.customerId.lastname ) ?  order.customerId.lastname : '',
          phone: (order.customerId && order.customerId.phone ) ?  order.customerId.phone : '',
          store: (order.storeId && order.storeId.name ) ?  order.storeId.name : '',
          price: order.price,
          date:order.orderDate
        }
        this.listOrders.push(ord);
      });
      this.table1.data = [...this.listOrders];
      this.pagination.currentPage = this.orders_info.currentPage;
      this.pagination.totalPages = this.orders_info.totalPages;
      this.pagination.totalRecords = this.orders_info.totalRecords;
    },
    async handlePageChange(page) {
      let storeId = this.selectedStore;
      await this.$store.dispatch('loadOrders',{
        storeId: storeId,
        searchQuery: this.searchQuery,        
        page: page,
        limit: this.limit
      });
      this.formData();
    }
  }
};
</script>
<style>

.actions-bar{
  margin-bottom: 29px;
}

</style>
